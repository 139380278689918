<template>
  <div>
     <Form label-position="top">
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="结算状态">
           <RadioGroup
          v-model="billStatus"
          type="button"
          size="small"
        >
          <Radio
            v-for="item in confirmStatus"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="创建周期">
          <Row :gutter="8">

            <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="起始日期"
          v-model="startDate"
          type="date"
          size="small"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="结束日期"
          v-model="endDate"
          type="date"
          size="small"
          placement="bottom-end"
          style="width: 100%"
        ></DatePicker>
      </i-col>
          </Row>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem label="公司">
             <Select v-model="currentsettlementCompanyId" size="small">
          <Option v-for="(company,index) in settlementcompanyArray" :key="index" :value="company.companyId">{{company.companyName}}</Option>
        </Select>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem label="供应商">
             <Select v-model="currentsupplierCompanyId"  size="small">
          <Option v-for="(company,index) in suppliercompanyArray" :key="index" :value="company.companyId">{{company.companyName}}</Option>
        </Select>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem  label="关键字">
           <Row :gutter="8">
              <i-col span="18">
         <i-input
        v-model="keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：编码、备注"
        ></i-input>
      </i-col>
      <i-col span="6">
      <Button
          type="primary"
          icon="ios-search"
          size="small"
          long
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
           </Row>
          </FormItem>
        </i-col>
      </Row>
    </Form>
    <Divider dashed><span class="divider-text">其他操作</span></Divider>
    <div class="m-b-5" >
      <Button  type="primary" size='small'  v-if="isAuth('maintenance_createSettlement')" @click="createNewSettlement" >新建结算</Button>
      <Button  type="success" size='small' class="m-l-5"  @click="showDownLoadProgrammeModal = true" >下载结算单</Button>
    </div>
    <createSettlement ref="createSettlement"></createSettlement>
    <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">请选择下载类型</h3>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
                <span>全部下载</span>
            </Radio>
            <Radio label="2">
                <span>勾选下载</span>
            </Radio>
        </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text"  @click="showDownLoadProgrammeModal=false">取消</Button>
        <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import { getcompanylistbytype } from '@/api/os/company'
import { getsettlements, getsuppliers } from '@/api/msp/settlement'
import createSettlement from './CreateMaintanceSettlement'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
export default {

  components: {
    createSettlement
  },
  data () {
    return {
      loadSearch: false,
      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 0, name: '草稿' },
        { id: 2, name: '已结算' }
      ],
      billStatus: -1,
      keyword: '',
      settlementcompanyArray: [],
      suppliercompanyArray: [],
      currentsupplierCompanyId: 0,
      currentsettlementCompanyId: 0,
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.currentsettlementCompanyId = this.maintenanceSettlementQuery.purchaserCompanyId
    this.currentsupplierCompanyId = this.maintenanceSettlementQuery.supplierCompanyId
    this.billStatus = this.maintenanceSettlementQuery.billStatus
    this.startDate = this.maintenanceSettlementQuery.startDate
    this.endDate = this.maintenanceSettlementQuery.endDate
    this.keyword = this.maintenanceSettlementQuery.keyword
    this.initsettlementCompanyArray()
  },
  methods: {
    createNewSettlement () {
      if (this.currentsupplierCompanyId) {
        this.$refs.createSettlement.showModal(this.settlementcompanyArray, this.currentsettlementCompanyId, this.suppliercompanyArray, this.currentsupplierCompanyId)
      }
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      getsettlements({}).then(res => {
        this.settlementcompanyArray = res
        this.currentsettlementCompanyId = this.maintenanceSettlementQuery.purchaserCompanyId || this.settlementcompanyArray[0].companyId
        this.inisupplierCompanyArray()
      })
    },
    // 获取供应商公司列表
    inisupplierCompanyArray () {
      getsuppliers({ }).then(res => {
        this.suppliercompanyArray = res
        this.currentsupplierCompanyId = this.maintenanceSettlementQuery.supplierCompanyId || this.suppliercompanyArray[0].companyId
        this.searchmaintenanceSettlementList()
      })
    },
    search () {
      this.$store.commit('set_reconciliationNew_maintenanceSettlementNumber', 1)

      this.searchmaintenanceSettlementList()
    },
    searchmaintenanceSettlementList () {
      var query = {
        purchaserCompanyId: this.currentsettlementCompanyId,
        supplierCompanyId: this.currentsupplierCompanyId,
        billStatus: this.billStatus
      }
      if (this.startDate) { query.startDate = ParseDate(this.startDate) }
      if (this.endDate) { query.endDate = ParseDate(this.endDate) }
      if (this.keyword) { query.keyword = this.keyword }

      this.$store.commit('set_reconciliationNew_maintenanceSettlementQuery', query)
      this.$store.commit('set_reconciliationNew_update', new Date())
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.supplierCompanyId = that.maintenanceSettlementQuery.supplierCompanyId
        params.purchaserCompanyId = that.maintenanceSettlementQuery.purchaserCompanyId
        params.startDate = that.maintenanceSettlementQuery.startDate
        params.endDate = that.maintenanceSettlementQuery.endDate
        params.keyword = that.maintenanceSettlementQuery.keyword
        params.billStatus = that.maintenanceSettlementQuery.billStatus === -1 ? null : that.maintenanceSettlementQuery.billStatus
        params.billType = 2
        params.publisherId = that.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.billIdArrJsonStr = JSON.stringify(that.feeitemIds)
          params.billType = 2
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }
      const filename = '运维结算单(' + dateStr + ').xlsx'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v2/bill/exportbills', params, filename)
    }
  },
  computed: {
    companyId () {
      return this.$store.state.reconciliationNew.companyId
    },
    maintenanceSettlementQuery () {
      return this.$store.state.reconciliationNew.maintenanceSettlementQuery
    },
    feeitemIds () {
      return this.$store.state.reconciliationNew.feeitemIds || []
    }
  }
}
</script>
